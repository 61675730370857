// import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/home';
import About from './pages/About'
import Services from './pages/Services';
import Blogs from './pages/Blogs';
import BestSeller from './pages/explore-best-seller';
import SignIn from './pages/sign-in';
import Register from './pages/sign-up';
import Navigation from "./common/navigation";
import Footer from "./common/footer";
import Consultation from './pages/Consultation';
function App() {
  return (
    <div className="App">
        <BrowserRouter>
        <Navigation/>
            <Routes>
                <Route path='/' element={<Home/>}/>
                <Route path='/about' element={<About/>}/>
                <Route path='/services' element={<Services/>}/>
                <Route path='/blogs' element={<Blogs/>}/>
                <Route path='/consultation' element={<Consultation/>}/>
            </Routes>
            <Footer />
        </BrowserRouter>
    </div>
  );
}

export default App;
